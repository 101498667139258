import React, { ReactElement } from 'react'
import Layout from '../../container/layout'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

const Container = styled.div`
  flex: 1;
  background-color: #6fd8b2;
  font-weight: 300;
  border-left: 12px solid #fff;
  border-right: 12px solid #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const NotFoundPage: React.FC = (): ReactElement => (
  <Layout>
    <Container>
      <h1>
        <FormattedMessage id={'not_found_title'} />
      </h1>
      <p>
        <FormattedMessage id={'not_found_paragraph'} />
      </p>
    </Container>
  </Layout>
)

export default NotFoundPage
